<template>
    <div class="modal modal-full-screen modal-fx-fadeInScale" :class="{ 'is-active': openEnquiry }">
        <div class="modal-background" @click="close()"></div>
        <div class="modal-card">
            <header class="modal-card-head is-info">
                <p class="modal-card-title">Make an Enquiry</p>
                <button class="delete" aria-label="close" @click.prevent="close"></button>
            </header>

            <section class="modal-card-body shrink">
                <div v-if="!messageSent">
                    <div class="enquiry-wrapper">
                        <div class="step-1">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/crew.jpg" />
                                </div>
                                <div>Your name</div>
                            </div>
                            <input type="text" placeholder="Please type your full name.." maxlength="100" v-model="smsEnquiry.contactName" class="input padded" />
                        </div>
                        <div class="spacer s20"></div>
                        <div class="step-2" v-if="smsEnquiry.contactName.length > 3">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/phone.jpg" />
                                </div>
                                <div>Your mobile number</div>
                            </div>
                            <div class="mobile-wrapper">
                                <div class="code"><vue-country-code @onSelect="updateCode" :preferredCountries="['au', 'nz', 'us']"></vue-country-code></div>
                                <div class="mobile">
                                    <input type="tel" placeholder="Enter your mobile number.." maxlength="15" v-model="smsEnquiry.phoneNumber" class="input padded" v-on:keypress="isNumber($event)" />
                                </div>
                            </div>
                            <!-- <p>Final Number: {{ finalNumber }}</p> -->
                        </div>
                        <div class="spacer s30"></div>
                        <div class="step-3" v-if="smsEnquiry.contactName.length > 3 && phoneNumberValid">
                            <div class="icon-label">
                                <div class="icon-img">
                                    <img src="@/assets/icons/target.jpg" />
                                </div>
                                <div>What can we help you with?</div>
                            </div>
                            <div class="select maxi">
                                <select v-model="smsEnquiry.enquiryType" @change="hasChosen = true">
                                    <option disabled value="">Choose one</option>
                                    <option v-for="(opt, idx) in enquiryOptions" :key="`opt-${idx}`">{{ opt }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="spacer s20"></div>
                    <span v-if="showSubmitButton" class="button is-medium is-primary" @click="triggerSmsEnquiry()"><font-awesome-icon icon="check" /> &nbsp; Submit My Enquiry</span>
                </div>
                <div v-else>
                    <div class="message-sent">
                        <div class="icon-img">
                            <img src="@/assets/icons/rocket.jpg" />
                        </div>
                        <div class="response">{{ enquiryMessage }}</div>
                    </div>
                    <!-- <div>In the meantime, browse our docs, or read some testimonials from other customers.</div> -->
                </div>
                <div class="spacer s60"></div>
            </section>

            <footer class="modal-card-foot">
                <button class="button cancel" @click.prevent="close">{{ messageSent ? 'Finish' : 'Cancel' }}</button>
            </footer>
        </div>
    </div>
</template>

<script>
import { db } from '../main.js';
import { store } from '../store/store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import NProgress from 'nprogress';

export default {
    components: {},
    data: function() {
        return {
            openEnquiry: true,
            smsEnquiry: {
                contactName: '',
                phoneNumber: '',
                enquiryType: ''
            },
            enquiryMessage: '',
            messageSent: false,
            areaCode: '',
            enquiryOptions: [
                'I need a safety management system',
                "I'm exploring fleet management solutions",
                "I'm looking for a planned maintenance system",
                "I'd like to know more about OffshoreSMS",
                'Something else..'
            ],
            hasChosen: false
        };
    },
    mounted() {
        let user = firebase.auth().currentUser;
        if (user != null) this.userID = user.uid;
    },
    computed: {
        emailValid() {
            return this.validateEmail();
        },
        phoneNumberValid() {
            // return this.smsEnquiry.phoneNumber.length > 10 && this.smsEnquiry.phoneNumber.slice(0,2) == '04';
            return this.smsEnquiry.phoneNumber.length > 6;
        },
        finalNumber() {
            // return "+61" + this.smsEnquiry.phoneNumber.slice(1,10);
            let tempNum = this.smsEnquiry.phoneNumber.slice(0, 1) == '0' ? this.smsEnquiry.phoneNumber.slice(1) : this.smsEnquiry.phoneNumber;
            return '+' + this.areaCode + tempNum;
        },
        showSubmitButton() {
            return !this.messageSent && this.hasChosen && this.phoneNumberValid && this.smsEnquiry.contactName.length > 3;
        }
    },

    methods: {
        onSlideChange() {
            console.log('slide change');
        },

        isNumber: function($evt) {
            $evt = $evt ? $evt : window.event;
            var charCode = $evt.which ? $evt.which : $evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                $evt.preventDefault();
            } else {
                return true;
            }
        },

        updateCode({ name, iso2, dialCode }) {
            // console.log(name, iso2, dialCode);
            this.areaCode = dialCode.toString();
        },

        close() {
            this.openEnquiry = false;
            this.enquiryMessage = '';
            this.messageSent = false;
            this.hasChosen = false;

            this.openEnquiry = false;
            let result = {
                answer: 'Modal closed',
                modal: false
            };
            this.$emit('closed', result);
        },

        validateEmail() {
            return this.newSignup.email == '' ? false : this.reg.test(this.newSignup.email) ? true : false;
        },

        triggerSmsEnquiry() {
            console.log('finalNumber', this.finalNumber);
            console.log('this.smsEnquiry', this.smsEnquiry);
            NProgress.start();
            var newQuickSmsEnquiry = firebase.functions().httpsCallable('newQuickSmsEnquiry');
            newQuickSmsEnquiry({
                userName: this.smsEnquiry.contactName,
                phoneNumber: this.finalNumber,
                enquiryType: this.smsEnquiry.enquiryType
            });

            setTimeout(() => {
                NProgress.done();
                this.enquiryMessage = "Enquiry submitted, thank you! We'll be in touch as quickly as we can.";
                this.messageSent = true;
            }, 500);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    &.modal-full-screen {
        .modal-card {
            max-height: 80%;
            max-height: 80vh;
            max-width: 1000px;
        }
    }
}
.modal-card-body {
    &.shrink {
        flex-grow: 0;
    }
}
.enquiry-wrapper {
    .step-1 {
    }
    .step-2 {
        .mobile-wrapper {
            display: flex;
            .code {
                width: 75px;
                margin-right: 6px;
                .vue-country-select {
                    height: 64px;
                    width: 100%;
                    .dropdown {
                        height: 100%;
                    }
                }
            }
            .mobile {
                flex-grow: 1;
            }
        }
    }
    .icon-label {
        display: flex;
        margin-bottom: 8px;
        color: cadetblue;
        text-transform: uppercase;
        font-weight: bold;
        align-items: center;
        .icon-img {
            margin-right: 6px;
            width: 30px;
            display: flex;
            align-items: center;
            img {
                width: 100%;
            }
        }
        .label-text {
            flex-grow: 1;
        }
    }
}
.message-sent {
    display: flex;
    margin-bottom: 30px;
    color: mediumseagreen;
    text-transform: uppercase;
    font-weight: bold;
    align-items: center;
    .icon-img {
        margin-right: 6px;
        width: 50px;
        display: flex;
        align-items: center;
        img {
            width: 100%;
        }
    }
    .response {
        flex-grow: 1;
    }
}
</style>
